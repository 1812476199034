import { useRef, useState, useEffect } from 'react'
import {
  Modal,
  ModalProps,
  Text,
  Stack,
  Button,
  ModalFooter,
  formatCurrency,
  IconButton,
  Box,
  TextInput,
  DayMonthYearInput,
  FormErrorMessage,
  FormHelperText,
} from '@thirstycamel/ui'
import omit from 'lodash.omit'
import type { CardDetails } from '@ts/core/src/modules/payment/payment.interfaces'

import { FRAUD_CHECK_ORDER_AMOUNT } from '../../utils/constants'
import dayjs from 'dayjs'
import { useUser } from '../../hooks/useUser'

type ErrorFields = 'lastFour' | 'dob'

const ModalOrderFraudCheckConfirmButton = ({
  label,
  value,
  onToggle,
  isConfirmed,
  ...restProps
}) => {
  return (
    <Stack isInline spacing={5} justify="space-between" {...restProps}>
      <Box>
        <Text fontSize="sm">{label}:</Text>
        <Text fontWeight="bold" fontSize="lg">
          {value}
        </Text>
        <FormHelperText mt={1}>This name should match their ID</FormHelperText>
      </Box>

      <IconButton
        icon="check"
        isRound
        variant={isConfirmed ? 'solid' : 'outline'}
        variantColor="green"
        size="lg"
        onClick={onToggle}
      />
    </Stack>
  )
}

export interface ModalOrderFraudCheckProps extends ModalProps {
  onDismiss?: () => void
  onConfirm?: () => void
  cardDetails?: CardDetails
  dateOfBirth?: string
}

const ModalOrderFraudCheck: React.FC<ModalOrderFraudCheckProps> = ({
  isVisible,
  onDismiss,
  onConfirm,
  cardDetails,
  dateOfBirth,
}) => {
  const { isAdmin } = useUser()

  const initialFocusRef = useRef(null)
  const dobInputRef = useRef(null)

  const [isNameConfirmed, setNameConfirmed] = useState(false)
  const [dob, setDob] = useState('')
  const [errors, setErrors] = useState<{ [key in ErrorFields]?: string }>({})

  const isReadyToSubmit = isNameConfirmed && dob

  const amount = formatCurrency(FRAUD_CHECK_ORDER_AMOUNT / 100, { truncateZeros: true })

  const handleChangeDob = value => {
    setDob(value)
    if (errors.dob) setErrors(state => omit(state, 'dob'))
  }

  const handleConfirm = () => {
    const isDobValid = Math.abs(dayjs(dateOfBirth).diff(dob, 'day')) <= 1 // 1 day lenience for simplicity with timezones etc

    const _errors: typeof errors = {}

    if (!isDobValid) _errors.dob = 'Incorrect date of birth.'

    setErrors(_errors)

    if (Object.keys(_errors).length > 0) return

    onDismiss?.()
    onConfirm?.()
  }

  return (
    <Modal
      onClose={onDismiss}
      isVisible={isVisible}
      initialFocusRef={initialFocusRef}
      heading="Fraud Check"
    >
      <Stack spacing={5}>
        <Stack spacing={2} pr={4}>
          <Text fontWeight="bold" fontSize="lg" w="15rem">
            Fraud checks are required for orders
          </Text>
          <Text>
            Before handing the customer their order, please confirm the customer's{' '}
            <b>full name + DOB on their ID</b> match the order details by entering them below.
          </Text>
          <Text>
            The customer acknowledged during checkout that{' '}
            <b>they must bring their ID and card used for payment</b> when collecting this order.
          </Text>
        </Stack>

        <Stack bg="gray.50" border="1px" borderColor="gray.200" p={5} spacing={4}>
          <ModalOrderFraudCheckConfirmButton
            label="Cardholder name"
            value={cardDetails?.full_name}
            onToggle={() => setNameConfirmed(state => !state)}
            isConfirmed={isNameConfirmed}
          />

          <Stack spacing={1}>
            <Text fontSize="sm">Date of Birth:</Text>
            <Box>
              <DayMonthYearInput
                onChangeValue={handleChangeDob}
                isInvalid={!!errors.dob}
                ref={dobInputRef}
              />
              {errors.dob && <FormErrorMessage error={errors.dob} />}
              <FormHelperText>The date of birth as shown on their ID</FormHelperText>
            </Box>
          </Stack>
        </Stack>

        {isAdmin && (
          <Button
            variantColor="blue"
            isRound
            onClick={() => {
              onDismiss?.()
              onConfirm?.()
            }}
          >
            Admin Approve
          </Button>
        )}

        <ModalFooter>
          <Button
            variant="outline"
            variantColor="pink"
            ref={initialFocusRef}
            isRound
            mr={3}
            onClick={onDismiss}
          >
            Cancel
          </Button>

          <Button variantColor="pink" isRound onClick={handleConfirm} isDisabled={!isReadyToSubmit}>
            Confirm
          </Button>
        </ModalFooter>
      </Stack>
    </Modal>
  )
}

export default ModalOrderFraudCheck
